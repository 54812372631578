import { useLocalizer } from '@nintendo-of-america/react-hooks';
import { useRouter } from '@nintendo-of-america/next';
import { Spacer, Image } from '@nintendo-of-america/component-library';
import { Grid } from '@shared/ui';

function MobileDisplay({ product }) {
  const { text } = useLocalizer();
  const { locale } = useRouter();
  const lang = locale?.split('-')[0] ?? 'en';
  return (
    <>
      <Spacer size={24} />
      <Grid columns={'144px 162px'} rows={'48px'}>
        {product?.appStoreUrl && (
          <a href={product.appStoreUrl}>
            <Image
              assetPath={`/ncom/icons/app-store/app-store-badge-${lang}`}
              alt={text('Download on the app store')}
            />
          </a>
        )}
        {product?.googlePlayUrl && (
          <a href={product.googlePlayUrl}>
            <Image
              assetPath={`/ncom/icons/google-play/google-play-badge-${lang}`}
              alt={text('Get it on Google Play')}
            />
          </a>
        )}
      </Grid>
    </>
  );
}

export default MobileDisplay;
