import {
  useEffect,
  useMemo,
  useCallback,
  useState,
  useRef,
  useContext,
} from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from '@nintendo-of-america/next';
import { ThemeContext } from 'styled-components';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import {
  Button,
  WishlistButton,
  MyNintendoPlatinumCoinIcon,
  ShoppingCartIcon,
  Price,
  DownloadIcon,
  Heading,
  Spacer,
  defaultTheme,
} from '@nintendo-of-america/component-library';
import GoldPoints from './GoldPoints';
import { Grid, GridItem } from '@shared/ui';
import {
  CUSTOM_DRAWER_TARGET,
  NAV_HEIGHT,
  useCustomDrawers,
} from '@nintendo-of-america/global-nav';
import { isLatam } from '@shared/util';
import withWishlistProps, {
  canAddToWishlist,
} from '@shared/util/withWishlistProps';
import { AddedToCart, PriceSpider, RedeemVoucher } from '@local/components';
import useMediaWidth, { MEDIA_TYPE } from '@local/lib/hooks/useMediaWidth';
import useScrollingDrawer from '@local/lib/hooks/useScrollingDrawer';
import {
  DRAWER_DESKTOP_HEIGHT,
  DRAWER_MOBILE_HEIGHT,
  ERROR_OFFSET,
} from '../../StickyCta/StickyCta.styles';
import ProductSelector from './ProductSelector';
import * as C from '../../';
import * as S from './PurchaseOptions.styles';
import { getSaleEndLabel } from './getSaleEndLabel';
import { ERROR_CODES } from './constants';

const RedemptionCodeModal = dynamic(
  () => import('../../../../RedemptionCodeModal'),
  { ssr: false }
);
const WishlistToggle = withWishlistProps(WishlistButton);

const ADD_TO_CART_DRAWER_ID = 'add-to-cart-drawer';
const ADDED_TO_CART_DRAWER_ID = 'added-to-cart-drawer';

// To ensure that drawers aren't preemptively removed,
// we're defining the drawer IDs array as a constant
// outside of the component so its reference is stable.
const DRAWER_IDS = [ADD_TO_CART_DRAWER_ID];

export default function PurchaseOptions({ product, purchaseOptions }) {
  const {
    actions: { openDrawer, closeDrawer, addOrUpdateDrawer, removeDrawer },
  } = useCustomDrawers();
  const addToCartDrawerRef = useRef();

  const isBundle = product.productType === 'BUNDLE';
  const configurableBundleItems = product.bundleItems?.filter((item) => {
    return item.options?.length > 1;
  });
  const theme = useContext(ThemeContext);

  const { locale } = useRouter();
  const lang = locale?.split('-')[0] ?? 'en';

  const { text } = useLocalizer();
  const isDesktop = useMediaWidth(MEDIA_TYPE.DESKTOP);

  const [addedQuantity, setAddedQuantity] = useState(0);
  const [addToCartErrorMessage, setAddToCartErrorMessage] = useState();

  const {
    state: {
      addableQty,
      quantity,
      isLoading,
      isMobileGame,
      isDigitalProduct,
      isPurchasable,
      retailerSku,
      buttonDisabled,
      promptLogin,
      disableQuantity,
      buttonCopy,
      helperText,
      boldHelperText,
      displayPrice,
      showRedemptionCodeModal,
      selectedOptionsByTitle,
    },
    actions: {
      handleDirectDownloadClick,
      handleAddToCart,
      setQuantity,
      setShowRedemptionCodeModal,
      setSelectedOptionsByTitle,
    },
  } = purchaseOptions;

  const { platinumPoints, eshopDetails } = product;

  const addedToCartDrawer = useMemo(
    () => ({
      id: ADDED_TO_CART_DRAWER_ID,
      drawer: {
        height: '325px',
        target: CUSTOM_DRAWER_TARGET.FLYOUT,
        bgColor: theme.color.lightGray3,
        enableScrim: true,
        content: (
          <AddedToCart
            product={product}
            quantity={addedQuantity}
            onClose={() => closeDrawer(ADDED_TO_CART_DRAWER_ID)}
          />
        ),
      },
    }),
    [product, addedQuantity, theme, closeDrawer]
  );

  const handleAddProductToCart = useCallback(async () => {
    setAddedQuantity(quantity);

    // Reset error message
    setAddToCartErrorMessage();

    const { data, errors } = (await handleAddToCart()) || {};

    const hasProductInCart = data?.cart?.items.some(
      ({ product: p }) => p.sku === product.sku
    );

    const hasErrors = errors?.length > 0;

    if (hasProductInCart && !hasErrors) {
      // If the resultant Cart has the product SKU
      // in its cart, we can assume the transaction
      // was successful
      openDrawer(ADDED_TO_CART_DRAWER_ID);
    } else if (hasErrors) {
      const errorCode = errors?.[0]?.extensions?.code;
      setAddToCartErrorMessage(ERROR_CODES[errorCode] || ERROR_CODES.DEFAULT);
    }
  }, [product, quantity, handleAddToCart, openDrawer]);

  const scrollingOptions = useMemo(
    () => ({
      viewPortTopOffset: isDesktop ? NAV_HEIGHT.DESKTOP : 0,
    }),
    [isDesktop]
  );

  const stickyCtaDrawerRefs = useMemo(
    () => ({
      [ADD_TO_CART_DRAWER_ID]: {
        ref: addToCartDrawerRef,
      },
    }),
    []
  );

  const drawerDesktopHeight = addToCartErrorMessage
    ? `${ERROR_OFFSET + parseInt(DRAWER_DESKTOP_HEIGHT)}px`
    : DRAWER_DESKTOP_HEIGHT;
  const drawerMobileHeight = addToCartErrorMessage
    ? `${ERROR_OFFSET + parseInt(DRAWER_MOBILE_HEIGHT)}px`
    : DRAWER_MOBILE_HEIGHT;

  const stickyCtaDrawers = useMemo(
    () => ({
      [ADD_TO_CART_DRAWER_ID]: {
        drawer: {
          height: isDesktop ? drawerDesktopHeight : drawerMobileHeight,
          target: CUSTOM_DRAWER_TARGET.TOP_DRAWER,
          bgColor: defaultTheme.color.lightGray3,
          content: (
            <C.StickyCta
              product={product}
              errorMessage={addToCartErrorMessage}
              purchaseOptions={{
                ...purchaseOptions,
                actions: {
                  ...purchaseOptions.actions,
                  handleAddToCart: handleAddProductToCart,
                },
              }}
            />
          ),
        },
      },
    }),
    [
      product,
      isDesktop,
      purchaseOptions,
      addToCartErrorMessage,
      handleAddProductToCart,
      drawerDesktopHeight,
      drawerMobileHeight,
    ]
  );

  useScrollingDrawer(
    DRAWER_IDS,
    stickyCtaDrawerRefs,
    stickyCtaDrawers,
    scrollingOptions
  );

  useEffect(() => {
    addOrUpdateDrawer(addedToCartDrawer.id, addedToCartDrawer.drawer);
  }, [addedToCartDrawer, addOrUpdateDrawer]);

  useEffect(() => {
    setQuantity(1);
  }, [setQuantity, addableQty]);

  useEffect(() => {
    // Reset the error message whenever the
    // product or quantity changes
    setAddToCartErrorMessage();
  }, [product?.sku, quantity]);

  useEffect(() => {
    return () => {
      removeDrawer(ADDED_TO_CART_DRAWER_ID);
    };
  }, [removeDrawer]);

  const saleEndLabel = useMemo(
    () =>
      eshopDetails?.discountPriceEnd
        ? getSaleEndLabel(eshopDetails?.discountPriceEnd, locale, text, () => {
            window.location.reload();
          })
        : null,
    [eshopDetails, locale, text]
  );

  const handleApplyRedemptionCode = async (code) => {
    const { data, errors } = await handleAddToCart(code);

    if (data?.cart?.id) {
      // Both the RedemptionCodeModal and the AddedToCart Flyout change the
      // `document.body`. Delay opening the drawer to ensure Modal's changes
      // are complete.
      setTimeout(() => {
        openDrawer(ADDED_TO_CART_DRAWER_ID);
      }, 200);
    } else {
      throw new Error(errors?.[0]?.extensions?.errors?.[0]?.message);
    }
  };

  return (
    <>
      {!isBundle && <ProductSelector product={product} />}
      <Spacer size={24} />
      {saleEndLabel && (
        <>
          <S.StyledStatusLabel
            backgroundColor={theme.color.primary}
            foregroundColor="#fff"
          >
            {saleEndLabel}
          </S.StyledStatusLabel>
          <Spacer size={8} />
        </>
      )}
      {!isMobileGame && (
        <S.Pricing>
          {isLatam(locale) && !isDigitalProduct ? null : isLoading ? (
            <S.PriceSkeleton width="50%" />
          ) : platinumPoints ? (
            <Grid columns={'24px 1fr'} justifyItems="start" alignItems="center">
              <MyNintendoPlatinumCoinIcon size={24} />
              <Heading variant="h2">
                {platinumPoints} {text('Platinum Points')}
              </Heading>
            </Grid>
          ) : (
            <Price
              regPrice={displayPrice.regPrice}
              size="large"
              salePrice={displayPrice.salePrice}
              lang={lang}
            />
          )}
          {canAddToWishlist(product) && (
            <WishlistToggle
              product={product}
              analyticsName="Product Detail Page wish list"
              iconOnly
            />
          )}
        </S.Pricing>
      )}
      {isBundle && (
        <S.BundleConfigOptions>
          {configurableBundleItems.map((bundleItem) => {
            const { options, title } = bundleItem;
            const defaultOption =
              options.find(({ isDefault }) => isDefault) || options[0];
            const configOption =
              defaultOption?.product?.configurableOptions?.[0]?.attributeCode?.toLowerCase();

            return (
              <ProductSelector
                key={bundleItem.id}
                onChange={(selectedOptionId) => {
                  const selectedOption = options.find(
                    ({ id }) => id === selectedOptionId
                  );
                  if (selectedOption.product?.isSalableQty) {
                    setSelectedOptionsByTitle((selected) => ({
                      ...selected,
                      [title]: selectedOptionId,
                    }));
                  }
                }}
                value={selectedOptionsByTitle[title]}
                title={text(`Select ${configOption} for {0}`, {
                  args: [title],
                })}
                isBundle
                product={{
                  ...defaultOption.product,
                  variations: options.map((bundleOption) => {
                    // Find the variation for each option so we can display a
                    // color swatch or size label.
                    const { label, value } =
                      bundleOption.product.variations.find(
                        (variant) =>
                          variant.product.sku == bundleOption.product.sku
                      );

                    return {
                      ...bundleOption,
                      label,
                      value,
                      product: { ...bundleOption.product, url: '' },
                    };
                  }),
                }}
              />
            );
          })}
        </S.BundleConfigOptions>
      )}
      {eshopDetails?.goldPoints ? (
        <>
          <Spacer size={24} />
          <GoldPoints eligiblePoints={eshopDetails.goldPoints} />
        </>
      ) : null}
      <Spacer size={24} />
      <Grid columns={'1fr 3fr'} alignItems="center">
        {isDigitalProduct ? (
          <GridItem column={'span 2'}>
            {(isPurchasable || product.__typename === 'MarketingProduct') && (
              <Button
                onClick={handleDirectDownloadClick}
                icon={buttonDisabled || promptLogin ? '' : DownloadIcon}
                isLoading={isLoading}
                buttonWidth="full"
                disabled={buttonDisabled}
                size="large"
                href={
                  buttonDisabled || promptLogin
                    ? null
                    : eshopDetails?.purchaseUrl
                }
              >
                {typeof buttonCopy === 'string' ? text(buttonCopy) : buttonCopy}
              </Button>
            )}
          </GridItem>
        ) : !isLatam(locale) ? (
          <>
            <S.QuantitySelector
              quantity={quantity}
              max={disableQuantity ? 1 : addableQty}
              min={1}
              onChange={setQuantity}
              disabled={buttonDisabled}
            />
            {/* TODO: WDEV-644 - Replace with button and textWrapping prop*/}
            <S.WrappingButton
              onClick={handleAddProductToCart}
              isLoading={isLoading}
              disabled={buttonDisabled}
              icon={buttonDisabled || promptLogin ? '' : ShoppingCartIcon}
              buttonWidth="full"
              size="large"
            >
              {typeof buttonCopy === 'string' ? text(buttonCopy) : buttonCopy}
            </S.WrappingButton>
          </>
        ) : null}
      </Grid>
      <span ref={addToCartDrawerRef} data-drawer-id={ADD_TO_CART_DRAWER_ID} />
      {helperText && (isDigitalProduct || !isLatam(locale)) && (
        <>
          <Spacer size={20} />
          {addToCartErrorMessage && (
            <S.ErrorText variant="caption">
              {text(addToCartErrorMessage)}
            </S.ErrorText>
          )}
          {product.voucherNsuid && <RedeemVoucher nsuid={product.nsuid} />}
          <S.Text variant="legal">
            <S.HelperText
              data-testid="helperText"
              $boldHelperText={boldHelperText}
            >
              {typeof helperText === 'string' ? text(helperText) : helperText}
            </S.HelperText>
          </S.Text>
        </>
      )}
      {retailerSku && !product.requiresSubscription ? (
        <>
          {(!isLatam(locale) || isDigitalProduct) && <Spacer size={24} />}
          <PriceSpider
            sku={retailerSku}
            name={product.name}
            type={isLatam(locale) && !isDigitalProduct ? 'button' : 'link'}
          />
        </>
      ) : null}
      {product.requiresCoupon && (
        <RedemptionCodeModal
          onApplyCode={handleApplyRedemptionCode}
          onClose={() => setShowRedemptionCodeModal(false)}
          visible={showRedemptionCodeModal}
        />
      )}
    </>
  );
}
