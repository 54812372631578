import { useMemo } from 'react';
import { useRouter } from '@nintendo-of-america/next';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import * as S from './OptionDisplays.styles';

function SelectDisplay({ product }) {
  const router = useRouter();
  const { text } = useLocalizer();

  const options = useMemo(() => {
    return product.variations.map((variation) => ({
      label: variation.product.nsuid ? text('Digital') : text('Physical'),
      caption: variation.product.name,
      price: product.loadingPricing
        ? null
        : variation.product?.prices?.minimum?.finalPrice?.toFixed(2),
      value: variation.product.name,
      urlKey: variation.product.urlKey,
    }));
  }, [product, text]);

  const selectedOption = useMemo(() => {
    return (
      options.find(({ urlKey }) => router.query.slug === urlKey) || options[0]
    );
  }, [options, router]);

  return (
    <S.StyledSelectDetailed
      style={{ zIndex: '100' }}
      inputName="select-a-product"
      inputLabel={text('Select a product')}
      onChange={(e) => {
        router.push(e.urlKey);
      }}
      options={options}
      value={selectedOption}
      showErrors={false}
    />
  );
}

export default SelectDisplay;
