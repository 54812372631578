import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from '@nintendo-of-america/next';
import * as S from './SelectAttributes.styles';
import { Title } from '@local/components/TileLink/TileLink.styles';

function toSwatchUrl(value) {
  if (!value) return null;
  return new URL(
    `/media/attribute/swatch/swatch_image/30x20${value}`,
    process.env.MAGENTO_URL
  ).toString();
}

// Product attributes that leverage swatch images
const swatchableAttributes = new Set(['color']);

const SelectAttributes = ({ onChange, product, value }) => {
  const { query } = useRouter();
  const attr = product.configurableOptions?.[0]?.attributeCode;

  return (
    <S.StyledAttributes>
      {product.variations.map((variation) => {
        const swatch =
          swatchableAttributes.has(attr) && toSwatchUrl(variation.value);
        const isActive = variation.product.url
          ? variation.product.urlKey === query.slug
          : variation.id === value;

        return variation.product.url ? (
          <S.TileLink
            href={variation.product.url}
            key={variation.product.name}
            isActive={isActive}
            swatch={swatch}
            disabled={!variation.product.isSalableQty}
          >
            {swatch ? '' : variation.label}
          </S.TileLink>
        ) : (
          <S.Tile
            onClick={() => onChange(variation.id)}
            key={variation.product.name}
            isActive={isActive}
            swatch={swatch}
            disabled={!variation.product.isSalableQty}
          >
            <Title type="portrait">{swatch ? '' : variation.label}</Title>
          </S.Tile>
        );
      })}
    </S.StyledAttributes>
  );
};

SelectAttributes.propTypes = {
  onChange: PropTypes.func,
  product: PropTypes.shape({
    configurableOptions: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.number,
        swatch: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    variants: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  value: PropTypes.string,
};

export default SelectAttributes;
