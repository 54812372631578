import styled, { css } from 'styled-components';
import { ContentRating } from '@local/components';

export const About = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    gap: ${theme.spacing[24]};
    ${theme.mediaQuery.tablet} {
      grid-template-columns: repeat(2, 1fr);
    }
  `}
`;

export const AboutItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
  background-color: ${({ theme }) => theme.color.lightGray4};
  padding: ${({ theme }) => theme.spacing[24] + ' ' + theme.spacing[32]};
  grid-column: ${(props) => (props.$solo ? 'span 2' : 'inherit')};
  border-radius: ${({ theme }) => theme.borderRadius};
`;

export const AboutRating = styled(ContentRating)`
  padding: ${({ theme }) => theme.spacing[16] + ' ' + theme.spacing[32]};
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0 4px 16px 0 rgba(60, 64, 89, 0.15);
  text-align: left;
  margin-bottom: ${({ theme }) => theme.spacing[32]};
  border-radius: ${({ theme }) => theme.borderRadiusSmall};
`;
