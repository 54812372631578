import styled, { css } from 'styled-components';

export const InfoSection = styled.div`
  margin: ${({ theme }) => theme.spacing[32]} 0;
`;

export const InfoRow = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 28px 1fr;
    align-items: start;
    gap: ${({ theme }) => theme.spacing[20]};
    grid-row-gap: 0;
    border-bottom: 1px solid ${({ theme }) => theme.color.lightGray2};
    padding: ${({ theme }) => `${theme.spacing[16]} ${theme.spacing[8]}`};

    svg {
      grid-row: 1 / 3;
    }

    &:nth-child(1) {
      border-top: 1px solid ${({ theme }) => theme.color.lightGray2};
    }
    ${theme.mediaQuery.tablet} {
      grid-template-columns: 28px 1fr 2fr;
      align-items: center;

      svg {
        grid-row: 1;
      }
    }
  `}
`;

export const InfoDescr = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing[8]};

  button {
    padding: 0;
  }
  ${({ theme }) => theme.mediaQuery.tablet} {
    gap: ${({ theme }) => theme.spacing[16]};
  }
`;

export const PlayModeIcon = styled.div`
  ${({ theme }) => css`
    background: ${(props) =>
      props.supported ? theme.color.secondary : 'rgba(60, 64, 89, 0.15)'};
    color: ${(props) => (props.supported ? '#fff' : '#c8c8c8')};
  `}
  padding: ${({ theme }) => theme.spacing[16]};
  display: grid;
  justify-content: center;
  box-shadow: 0 4px 16px 0 rgba(60, 64, 89, 0.15);
  border-radius: ${({ theme }) => theme.borderRadius};
`;

export const Test = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #e60012;
  width: 100%;
  padding: 1em ${({ theme }) => theme.spacing[32]};
`;
