import styled from 'styled-components';
import {
  Text,
  Heading,
  Button as DSButton,
  MyNintendoPlatinumCoinIcon as DSMyNintendoPlatinumCoinIcon,
  Skeleton,
} from '@nintendo-of-america/component-library';
import { Grid as SharedUIGrid } from '@shared/ui';
import {
  CONSTRAINED_MAX_WIDTH_SMALL,
  CONSTRAINED_WIDTH,
} from '@local/components/constrained';

export const DRAWER_DESKTOP_HEIGHT = '64px';
export const DRAWER_MOBILE_HEIGHT = '110px';

export const PLATINUM_ICON_WIDTH_MOBILE = '14px';
export const PLATINUM_ICON_WIDTH_DESKTOP = '20px';
export const ERROR_OFFSET = 10;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: ${({ $hasError }) =>
    $hasError
      ? `${ERROR_OFFSET + parseInt(DRAWER_MOBILE_HEIGHT)}px`
      : DRAWER_MOBILE_HEIGHT};
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 1rem;
  transition: height 150ms ease-in-out;

  ${({ theme }) => theme.mediaQuery.desktop} {
    align-items: center;
    width: ${CONSTRAINED_WIDTH};
    max-width: ${CONSTRAINED_MAX_WIDTH_SMALL};
    height: ${({ $hasError }) =>
      $hasError
        ? `${ERROR_OFFSET + parseInt(DRAWER_DESKTOP_HEIGHT)}px`
        : DRAWER_DESKTOP_HEIGHT};
    padding-top: 0;
  }
`;

export const CtaContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;

  ${({ theme }) => theme.mediaQuery.desktop} {
    width: initial;
    justify-content: flex-end;
    padding: 0;
  }
`;

export const ProductName = styled(Heading)`
  display: none;

  ${({ theme }) => theme.mediaQuery.desktop} {
    display: block;
  }
`;

export const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing[4]};
  width: 50%;

  ${({ theme }) => theme.mediaQuery.desktop} {
    align-items: center;
    flex-direction: row;
    width: initial;
    row-gap: unset;
  }
`;

export const PricingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 1rem;
  position: relative;
`;

export const Pricing = styled.div`
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
`;

export const PricingSkeleton = styled(Skeleton)`
  position: absolute;
  top: -3px;
  left: 0;
  z-index: 1;
`;

export const VariantLabel = styled.span`
  font-size: ${({ theme }) => theme.font.size.legalDesktop};
  font-weight: ${({ theme }) => theme.font.weight.bold};

  ${({ theme }) => theme.mediaQuery.desktop} {
    margin-right: ${({ $smallMargin }) => ($smallMargin ? '0.5rem' : '1rem')};
    white-space: nowrap;
  }
`;

export const Button = styled(DSButton)`
  width: 100%;
  min-width: ${({ theme }) => theme.pxToRem(180)};

  ${({ theme }) => theme.mediaQuery.tablet} {
    min-width: ${({ theme }) => theme.pxToRem(225)};
  }
`;

export const Grid = styled(SharedUIGrid)`
  grid-template-columns: ${PLATINUM_ICON_WIDTH_MOBILE} 1fr;

  ${({ theme }) => theme.mediaQuery.desktop} {
    grid-template-columns: ${PLATINUM_ICON_WIDTH_DESKTOP} 1fr;
  }
`;

export const MyNintendoPlatinumCoinIcon = styled(DSMyNintendoPlatinumCoinIcon)`
  width: ${PLATINUM_ICON_WIDTH_MOBILE};

  ${({ theme }) => theme.mediaQuery.desktop} {
    width: ${PLATINUM_ICON_WIDTH_DESKTOP};
  }
`;

export const PlatinumPointsLabel = styled(Heading)`
  font-size: ${({ theme }) => theme.font.size.titleMobile};

  ${({ theme }) => theme.mediaQuery.desktop} {
    font-size: ${({ theme }) => theme.font.size.h3Desktop};
  }
`;

export const ErrorText = styled(Text)`
  color: ${({ theme }) => theme.color.primary};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  text-align: center;
  margin-top: 0.25rem;
`;
