import PropTypes from 'prop-types';
import {
  RadioDisplay,
  SelectDisplay,
  SwatchDisplay,
  MobileDisplay,
} from './OptionDisplays';

const ProductSelector = ({ onChange, isBundle, product, title, value }) => {
  const { topLevelCategory, variations, nsuid } = product;

  if (topLevelCategory?.code === 'GAMES') {
    if (nsuid == 'MOBILE') {
      return <MobileDisplay product={product} />;
    } else if (variations?.length > 3) {
      return <SelectDisplay product={product} />;
    } else if (variations?.length > 0) {
      return (
        <RadioDisplay
          onChange={onChange}
          isBundle={isBundle}
          product={product}
          title={title}
          value={value}
        />
      );
    }
  }

  if (variations?.length > 0) {
    return (
      <SwatchDisplay
        isBundle={isBundle}
        onChange={onChange}
        product={product}
        title={title}
        value={value}
      />
    );
  }

  return null;
};

ProductSelector.propTypes = {
  onChange: PropTypes.func,
  product: PropTypes.shape({
    topLevelCategory: PropTypes.shape({ code: PropTypes.string }),
    nsuid: PropTypes.string,
    variations: PropTypes.arrayOf(
      PropTypes.shape({
        product: PropTypes.shape({
          nsuid: PropTypes.string,
          name: PropTypes.string,
          urlKey: PropTypes.string,
          prices: PropTypes.shape({
            minimum: PropTypes.shape({ finalPrice: PropTypes.number }),
          }),
        }),
      })
    ),
  }),
  value: PropTypes.string,
};

export default ProductSelector;
