import styled, { css } from 'styled-components';

export const PlayMode = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;

  p {
    font-weight: 700;
  }
`;

export const PlayModeIcon = styled.div`
  ${({ theme }) => css`
    background: ${({ $supported }) =>
      $supported ? theme.color.darkGray2 : theme.color.lightGray2};
    color: ${theme.color.white};
  `}
  padding: ${({ theme }) => theme.spacing[16]};
  display: grid;
  justify-content: center;
  box-shadow: 0 4px 16px 0 rgba(60, 64, 89, 0.15);
  border-radius: ${({ theme }) => theme.borderRadiusSmall};
`;
