import { useEffect, useLayoutEffect, useCallback } from 'react';
import { useCustomDrawers } from '@nintendo-of-america/global-nav';

const useScrollingDrawer = (ids, triggerRefs, drawers, options) => {
  const {
    actions: { openDrawer, closeDrawer, addOrUpdateDrawer, removeDrawer },
  } = useCustomDrawers();

  const { viewPortTopOffset = 0, disableDrawers } = options || {};

  const handleIntersectionObserver = useCallback(
    (entries) => {
      entries.forEach((entry) => {
        const { target, boundingClientRect } = entry;

        const showDrawer = boundingClientRect?.top < viewPortTopOffset;
        const id = target?.dataset?.drawerId;

        // Can't find an ID, then
        // no drawer to open/close
        if (!id) return;

        if (showDrawer) {
          openDrawer(id);
        } else {
          closeDrawer(id);
        }
      });
    },
    [openDrawer, closeDrawer, viewPortTopOffset]
  );

  useEffect(() => {
    if (disableDrawers) {
      return;
    }

    ids.forEach((id) => {
      addOrUpdateDrawer(id, drawers[id].drawer);
    });
  }, [addOrUpdateDrawer, ids, drawers, disableDrawers]);

  useEffect(() => {
    if (disableDrawers) {
      return;
    }

    return () => {
      ids.forEach((id) => {
        removeDrawer(id);
      });
    };
  }, [removeDrawer, ids, disableDrawers]);

  useLayoutEffect(() => {
    if (disableDrawers) {
      return;
    }

    const observer = new IntersectionObserver(handleIntersectionObserver, {
      rootMargin: `-${viewPortTopOffset}px 0px 0px 0px`,
      threshold: 1.0,
    });

    ids.forEach((id) => {
      if (triggerRefs[id].ref.current) {
        observer.observe(triggerRefs[id].ref.current);
      }
    });

    return () => {
      ids.forEach((id) => {
        if (triggerRefs[id].ref.current) {
          observer.unobserve(triggerRefs[id].ref.current);
        }
      });
    };
  }, [
    handleIntersectionObserver,
    ids,
    triggerRefs,
    disableDrawers,
    viewPortTopOffset,
  ]);
};

export default useScrollingDrawer;
