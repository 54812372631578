import { useMemo } from 'react';
import {
  useFeature,
  useCustomer,
  useLocalizer,
} from '@nintendo-of-america/react-hooks';
import { useRouter } from '@nintendo-of-america/next';
import { FF_NAMES } from '@shared/util/constants';
import { isOnCorrectLocale } from '@shared/util';

const localeExchangeRateMap = {
  'en-us': 0.01,
  'en-ca': 0.01,
  'fr-ca': 0.01,
  'es-mx': 0.2,
  'pt-br': 0.05,
  'es-ar': 0.5,
  'es-cl': 5.0,
  'es-co': 50.0,
  'es-pe': 0.05,
};

export default function useGoldPointsMessage() {
  const customer = useCustomer();
  const feature = useFeature(FF_NAMES.GOLD_POINTS_DISCOUNT);
  const { locale } = useRouter();
  const { price } = useLocalizer();

  const count = useMemo(
    () => customer?.data?.pointsWallet?.gold?.total || 0,
    [customer]
  );
  const showValue = useMemo(
    () =>
      customer.loading === false &&
      isOnCorrectLocale(locale, customer?.data) &&
      count >= 100,
    [count, customer, locale]
  );
  const total = useMemo(
    () => Intl.NumberFormat(locale).format(count),
    [locale, count]
  );
  const value = useMemo(
    () => price(count * localeExchangeRateMap[locale]),
    [locale, count, price]
  );

  return {
    loading: feature?.loading || customer?.loading,
    newMessage: feature?.enabled,
    goldPoints: {
      showValue,
      total,
      value,
    },
  };
}
