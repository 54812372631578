import { useState, useEffect, useMemo, useCallback } from 'react';
import { useRouter } from '@nintendo-of-america/next';
import {
  CookieStorageCache,
  useCustomer,
  useLocalizer,
  useRecentlyViewed,
  useAnalytics,
} from '@nintendo-of-america/react-hooks';
import {
  AgeGate,
  Button,
  HeadingCta,
  Spacer,
  Rail,
  NewsTile,
  Image,
  WarningIcon,
  Heading,
  defaultTheme as theme,
  Link,
  Text,
  OffsiteIcon,
} from '@nintendo-of-america/component-library';
import { DEFAULT_LOCALE, US_CA_LOCALES } from '@shared/util/constants';
import { useRestoreScroll } from '@local/lib/hooks';
import {
  Section,
  RecentlyViewed,
  StoryModule,
  ProductCollections,
  RichText,
} from '@local/components';
import * as S from './ProductDetail.styles';
import { Hero, ReadMore, About, SizeChart, NSOStory, DlcRail } from '../index';
import { storeProductParser } from '@shared/util';
import { createGTMItemDetails, useMountEffect } from '@shared/util';
import { StoreProductTile } from '@shared/ui';

const thirtyMinutes = 60 * 30;
const ageGateCookie = new CookieStorageCache(
  'nintendo.ageGate.isOldEnough',
  thirtyMinutes
);

export default function ProductDetail({
  children,
  product,
  merchBanner,
  showReadMore,
  bestSellers,
}) {
  const { text, date, DateFormat } = useLocalizer();
  const { locale } = useRouter();
  const customer = useCustomer();
  const showDLC = product.relatedProducts?.length > 0;
  const recentlyViewed = useRecentlyViewed();
  const { trackViewItem } = useAnalytics();
  const [hasViewed, setHasViewed] = useState(false);

  const ageGated = product.ageGate || product.contentRating?.requiresAgeGate;
  const isOldEnough = ageGateCookie.get();
  const [lang, country] = locale.split('-');
  const formattedSite = product.officialSite?.startsWith('http')
    ? product.officialSite
    : `//${product.officialSite}`;
  const relatedArticles = product.relatedArticles;
  const bottomRails = useMemo(() => {
    const railsData = [];

    if (US_CA_LOCALES.has(locale)) {
      if (product.upsellProducts?.length) {
        railsData.push({
          heading: text('Related items to explore'),
          products: product.upsellProducts.slice(0, 16).map(storeProductParser),
        });
      }
      if (product.crossSellProducts?.length) {
        railsData.push({
          heading: text('More like this'),
          products: product.crossSellProducts
            .slice(0, 16)
            .map(storeProductParser),
        });
      }
      if (bestSellers?.length) {
        railsData.push({
          heading: text('Digital best sellers'),
          products: bestSellers.map(storeProductParser),
        });
      }
    }
    return railsData;
  }, [
    product.upsellProducts,
    product.crossSellProducts,
    bestSellers,
    text,
    locale,
  ]);

  const { waitForElementRef } = useRestoreScroll();

  const handleContentMounted = useCallback(() => {
    waitForElementRef(true);
  }, [waitForElementRef]);

  useEffect(() => {
    if (recentlyViewed.addProduct && !hasViewed) {
      setHasViewed(true);

      if (product.edition) {
        recentlyViewed.addProduct(product);
      } else {
        recentlyViewed.addProduct(product.configurableProduct || product);
      }
    }
  }, [recentlyViewed, product, hasViewed]);

  useMountEffect(() =>
    trackViewItem(product, createGTMItemDetails({ name: product.name }))
  );

  // Wait until customer has loaded so we know whether it is a child account or not
  return ageGated && customer.loading ? (
    <S.AgeGateLoadingContainer />
  ) : (
    <AgeGate
      passed={(isOldEnough === 'true' && !customer.data?.isChild) || !ageGated}
      failed={(customer.data?.isChild || isOldEnough === 'false') && ageGated}
      onSuccess={() => ageGateCookie.set('true', thirtyMinutes)}
      onFailure={() => ageGateCookie.set('false', thirtyMinutes)}
      lang={lang}
      country={country}
      minimumAge={country === 'mx' ? 18 : 17}
    >
      <Hero product={product} />
      {product.productType === 'BUNDLE' && (
        <Section constrained small>
          <Spacer size={24} />
          <Heading variant="h1">{text('Included in this bundle')}</Heading>
          <Rail contained>
            {product.bundleItems
              // TODO: Why do these not have options? (WDEV-1236)
              ?.filter((item) => !!item.options.length)
              .map((item) => {
                const defaultOption = item.options.find(
                  ({ isDefault }) => isDefault
                );
                const product =
                  defaultOption?.product ?? item.options[0]?.product;

                // Only show the variations in the expansion slot when the
                // bundle has "configurable" options
                const variations =
                  item.options.length > 1 ? product.variations : [];

                return (
                  <StoreProductTile
                    {...product}
                    isSalableQty={item.options.some(
                      (opt) => opt?.product?.isSalableQty
                    )}
                    variations={variations}
                    artPath={product?.productImage?.publicId}
                    platform={product?.platform?.label}
                    platformCode={product?.platform?.code}
                    key={product.sku}
                    showRating
                  />
                );
              })}
          </Rail>
        </Section>
      )}
      <Heading.NewLevel>
        {showReadMore && (
          <S.ReadMoreSection constrained small marginBottom={0}>
            <S.Grid>
              <div>
                {product.headline && <Heading>{product.headline}</Heading>}
                <ReadMore
                  maxLines={10}
                  description={
                    product.richTextDescription?.json || product.description
                  }
                  category={product.topLevelCategory}
                />
                {product.officialSite && (
                  <a
                    href={formattedSite}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button>
                      {text("Explore this game's official website")}
                    </Button>
                  </a>
                )}
              </div>
              {(product.descriptionImage || product.productImage) && (
                <S.DescrImageContainer>
                  <div>
                    <Image
                      assetPath={
                        product.descriptionImage
                          ? product.descriptionImage.publicId
                          : product.productImage.publicId
                      }
                      alt={product.name}
                    />
                  </div>
                </S.DescrImageContainer>
              )}
            </S.Grid>
          </S.ReadMoreSection>
        )}
        {merchBanner?.asset?.primary?.assetPath ? (
          <S.BannerSection
            constrained
            small
            background={merchBanner.background?.backgroundType}
            id="banner"
          >
            <StoryModule
              content={{
                assetPath: merchBanner.asset?.primary?.assetPath,
                assetPathAlt: merchBanner.asset?.alt,
                cta: merchBanner.ctaList[0],
                description: merchBanner.description,
                heading: merchBanner.heading,
                modifiers: merchBanner.modifiers,
              }}
            />
          </S.BannerSection>
        ) : null}
        {children}
        {product.publisherNotifications && (
          <Section constrained small marginBottom={0}>
            <S.PublisherNotifications>
              <Heading variant="h3">
                {text('Publisher notification(s):')}
              </Heading>
              <RichText
                textVariant="legal"
                data={product.publisherNotifications}
              />
            </S.PublisherNotifications>
          </Section>
        )}
        {relatedArticles?.length > 0 && locale === DEFAULT_LOCALE && (
          <Section constrained small>
            <HeadingCta
              ctaText={text('Finding this content helpful?')}
              ctaType="iconlink"
              ctaUrl="https://nintendoofamerica.co1.qualtrics.com/jfe/form/SV_ahCL4zK2P7j2q3k"
              icon={OffsiteIcon}
              ctaTarget="_blank"
            >
              {text('Related news and events')}
            </HeadingCta>
            <Spacer size={16} />
            <Rail contained>
              {relatedArticles.map(({ id, url, title, media, publishDate }) => (
                <NewsTile
                  key={id}
                  articleLink={url}
                  articleTitle={title}
                  artPath={media?.publicId}
                  publishDate={date(publishDate, { format: DateFormat.SHORT })}
                  readMoreLabel={text('Read more')}
                  size="small"
                />
              ))}
            </Rail>
          </Section>
        )}
        <About product={product} locale={locale} />
        {product.sizeChart && (
          <Section
            id="sizing"
            constrained={true}
            background={theme.color.lightGray4}
            small
          >
            <h2>{text('Size chart')}</h2>
            <SizeChart product={product} />
          </Section>
        )}
        <NSOStory product={product}></NSOStory>
        {showDLC ? (
          <Section id="dlc" small>
            <DlcRail product={product} locale={locale} />
          </Section>
        ) : null}

        <Section small constrained marginBottom={16}>
          <ProductCollections collections={bottomRails} />
        </Section>
        <RecentlyViewed
          currentSku={
            product.edition
              ? product.sku
              : product.configurableProduct?.sku || product.sku
          }
          small
          onProductsLoaded={handleContentMounted}
        />
        <Section id="legal" constrained={true} small>
          {product.topLevelCategory?.code === 'GAMES' && (
            <Text variant="legal">
              {text(
                'WARNING: If you have epilepsy or have had seizures or other unusual reactions to flashing lights or patterns, consult a doctor before playing video games. All users should read the Health and Safety Information available in the system settings before using this software.'
              )}
            </Text>
          )}
          {product.disclaimer && (
            <S.legal dangerouslySetInnerHTML={{ __html: product.disclaimer }} />
          )}
          {product.richTextDisclaimer?.json && (
            <RichText
              textVariant="legal"
              data={product.richTextDisclaimer?.json}
            />
          )}
          {product.displayProp65 && (
            <S.legal>
              <S.PropSixFive>
                <WarningIcon size={16} />
                <Heading variant="h3">{text('Warning:')}</Heading>{' '}
                {text('Cancer and reproductive harm.')}
              </S.PropSixFive>
              <Link href="https://www.p65warnings.ca.gov/">
                p65warnings.ca.gov
              </Link>
            </S.legal>
          )}
        </Section>
      </Heading.NewLevel>
    </AgeGate>
  );
}
