import styled, { css } from 'styled-components';
import { Constrained } from '@local/components';

export const SizeChart = styled(Constrained)`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: ${theme.spacing[24]};
    grid-column-gap: ${theme.spacing[48]};
    align-items: center;
    box-shadow: 0 2px 16px 0 rgba(72, 72, 72, 0.15);
    border-radius: 4px;
    padding: 3em;
    margin-bottom: ${({ theme }) => theme.spacing[32]};
    background: #fff;
    ${theme.mediaQuery.tablet} {
      grid-template-columns: 1fr 1fr;
    }
  `}
`;

export const SizeTable = styled.table`
  text-align: left;
  border-collapse: collapse;

  tr:nth-child(2n + 3) {
    background: ${({ theme }) => theme.color.lightGray4};
  }

  th,
  td {
    padding: ${({ theme }) => `${theme.spacing[16]} ${theme.spacing[8]}`};
    border: none;
  }
`;

export const ImageContainer = styled.div`
  img {
    object-fit: contain;
    max-height: 24em;
  }
`;

export const Test = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #e60012;
  width: 100%;
  padding: 1em 2em;
`;
