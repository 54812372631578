import { Text, Heading } from '@nintendo-of-america/component-library';
import { Section } from '@local/components';
import { useRouter } from '@nintendo-of-america/next';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import { Grid } from '@shared/ui';

import * as S from './About.styles';
import * as C from '../';
import { MODES } from '../PlayMode/PlayMode';

const About = ({ product }) => {
  const { locale } = useRouter();
  const { text } = useLocalizer();
  const playModes = product.playModes?.map((mode) => mode.code);

  return (
    <Section constrained id="about" aria-labelledby="product-info" small>
      {product.contentRating != null || playModes?.length ? (
        <S.About>
          {product.contentRating != null && (
            <S.AboutItem $solo={!playModes?.length}>
              <Heading variant="h4">{text('ESRB rating')}</Heading>
              <S.AboutRating
                rating={product.contentRating}
                descriptors={product.contentDescriptors}
                locale={locale}
              />
            </S.AboutItem>
          )}
          {playModes?.length > 0 && (
            <S.AboutItem $solo={!product.contentRating}>
              <Heading variant="h4">{text('Supported play modes')}</Heading>
              <Grid columns={3} width="100%" gap={'24px'}>
                <C.PlayMode
                  supported={playModes?.includes('TV_MODE')}
                  title={text('TV')}
                  mode={MODES.TV}
                />
                <C.PlayMode
                  supported={playModes?.includes('TABLETOP_MODE')}
                  title={text('Tabletop')}
                  mode={MODES.TABLETOP}
                />
                <C.PlayMode
                  supported={playModes?.includes('HANDHELD_MODE')}
                  title={text('Handheld')}
                  mode={MODES.HANDHELD}
                />
              </Grid>
              {!playModes?.includes('HANDHELD_MODE') && (
                <Text variant="legal">
                  {text(
                    'Software compatibility and play experience may differ on Nintendo Switch Lite. Additional accessories may be required (sold separately). See support for details.'
                  )}
                </Text>
              )}
            </S.AboutItem>
          )}
        </S.About>
      ) : null}
      {product.productType !== 'BUNDLE' && <C.ProductInfo product={product} />}
    </Section>
  );
};

export default About;
