import { Image, WarningIcon } from '@nintendo-of-america/component-library';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import * as S from './SizeChart.styles';

const SizeChart = ({ product }) => {
  const chart = charts[product.sizeChart.code];
  const { text } = useLocalizer();

  return (
    <S.SizeChart>
      <S.ImageContainer>
        <Image
          assetPath={
            product.descriptionImage
              ? product.descriptionImage.publicId
              : product.productImage.publicId
          }
          alt={product.name}
        />
      </S.ImageContainer>
      <S.SizeTable>
        {chart ? (
          <tbody>
            <tr>
              <th>{text('Size')}</th>
              <th>{text(chart?.type === 'pants' ? 'Waist' : 'Width')}</th>
              <th>{text(chart?.type === 'pants' ? 'Inseam' : 'Length')}</th>
            </tr>
            {chart.sizes.map((size) => (
              <tr key={size.label}>
                <td>{text(size.label)}</td>
                <td>{size.width}&quot;</td>
                <td>{size.length}&quot;</td>
              </tr>
            ))}
          </tbody>
        ) : (
          <p>
            <WarningIcon size={16} /> Size chart unavailable.
          </p>
        )}
      </S.SizeTable>
    </S.SizeChart>
  );
};

const charts = {
  868: {
    label: 'tshirt',
    type: 'shirt',
    sizes: [
      { label: 'XS', width: '17.5', length: '27' },
      { label: 'Small', width: '19', length: '28' },
      { label: 'Medium', width: '20.5', length: '29' },
      { label: 'Large', width: '22', length: '30' },
      { label: 'XL', width: '24', length: '31' },
      { label: '2XL', width: '26', length: '32' },
      { label: '3XL', width: '28', length: '33' },
      { label: '4XL', width: '30', length: '34' },
    ],
  },
  23198: {
    label: 'FifthSunHoodie',
    type: 'shirt',
    sizes: [
      { label: 'Small', width: '20.5', length: '26.5' },
      { label: 'Medium', width: '22', length: '28' },
      { label: 'Large', width: '23.5', length: '29.5' },
      { label: 'X-Large', width: '25', length: '31' },
      { label: 'XX-Large', width: '26.5', length: '32.5' },
    ],
  },
  23201: {
    label: 'FifthSunMen',
    type: 'shirt',
    sizes: [
      { label: 'Small', width: '18', length: '28' },
      { label: 'Medium', width: '20.5', length: '29' },
      { label: 'Large', width: '22', length: '30' },
      { label: 'X-Large', width: '24', length: '31' },
      { label: 'XX-Large', width: '26', length: '32' },
    ],
  },
  23204: {
    label: 'Fifth Sun - Women',
    type: 'shirt',
    sizes: [
      { label: 'Small', width: '16.5', length: '25' },
      { label: 'Medium', width: '17.5', length: '26' },
      { label: 'Large', width: '19', length: '27' },
      { label: 'X-Large', width: '20.5', length: '28' },
      { label: 'XX-Large', width: '22', length: '28.5' },
    ],
  },
  23219: {
    label: 'SportiqeBomberJacket',
    type: 'shirt',
    sizes: [
      { label: 'X-Small', width: '42', length: '25 1/2' },
      { label: 'Small', width: '44', length: '26 1/4' },
      { label: 'Medium', width: '46', length: '27' },
      { label: 'Large', width: '48', length: '27 3/4' },
      { label: 'X-Large', width: '50', length: '28 1/2' },
      { label: 'XX-Large', width: '52', length: '29 1/4' },
      { label: 'XXX-Large', width: '54', length: '30' },
    ],
  },
  23207: {
    label: 'SportiqeDavis',
    type: 'shirt',
    sizes: [
      { label: 'Small', width: '19', length: '28' },
      { label: 'Medium', width: '21', length: '29' },
      { label: 'Large', width: '22', length: '30' },
      { label: 'X-Large', width: '24', length: '31' },
      { label: 'XX-Large', width: '26', length: '32' },
      { label: 'XXX-Large', width: '28', length: '33' },
      { label: 'XXXX-Large', width: '30', length: '34' },
    ],
  },
  23216: {
    label: 'SportiqeDavisWomen',
    type: 'shirt',
    sizes: [
      { label: 'X-Small', width: '30 1/2', length: '25 3/4' },
      { label: 'Small', width: '32 1/2', length: '26 3/8' },
      { label: 'Medium', width: '34 1/2', length: '27' },
      { label: 'Large', width: '36 1/2', length: '27 5/8' },
      { label: 'X-Large', width: '39 1/2', length: '28 1/4' },
      { label: 'XX-Large', width: '42 1/2', length: '28 7/8' },
    ],
  },
  23213: {
    label: 'SportiqeHeywood',
    type: 'shirt',
    sizes: [
      { label: 'Small', width: '39', length: '26' },
      { label: 'Medium', width: '41', length: '27' },
      { label: 'Large', width: '43', length: '28' },
      { label: 'X-Large', width: '45', length: '29' },
      { label: 'XX-Large', width: '47', length: '30' },
      { label: 'XXX-Large', width: '49', length: '33' },
    ],
  },
  23210: {
    label: 'SportiqeOlsen',
    type: 'shirt',
    sizes: [
      { label: 'Small', width: '21', length: '27' },
      { label: 'Medium', width: '22', length: '28' },
      { label: 'Large', width: '23', length: '29' },
      { label: 'X-Large', width: '24', length: '30' },
      { label: 'XX-Large', width: '25', length: '31' },
      { label: 'XXX-Large', width: '26', length: '32' },
    ],
  },
  26449: {
    label: 'SportiqeBlake',
    type: 'shirt',
    sizes: [
      { label: 'XS', width: '39', length: '26.5' },
      { label: 'S', width: '41', length: '27.5' },
      { label: 'M', width: '43', length: '28.5' },
      { label: 'L', width: '45', length: '29.5' },
      { label: 'XL', width: '47', length: '30.5' },
      { label: '2XL', width: '49', length: '31.5' },
      { label: '3XL', width: '50', length: '32.5' },
    ],
  },
  26467: {
    label: 'SportiqeJo',
    type: 'pants',
    sizes: [
      { label: 'S', width: '26', length: '28' },
      { label: 'M', width: '28', length: '28.5' },
      { label: 'L', width: '30', length: '29' },
      { label: 'XL', width: '32', length: '29.5' },
      { label: '2XL', width: '34', length: '30' },
    ],
  },
  23192: {
    label: 'BioworldMen',
    type: 'shirt',
    sizes: [
      { label: 'S', width: '18', length: '28' },
      { label: 'M', width: '20', length: '29' },
      { label: 'L', width: '22', length: '30' },
      { label: 'XL', width: '24', length: '31' },
      { label: '2XL', width: '26', length: '32' },
      { label: '3XL', width: '28', length: '33' },
    ],
  },
  26470: {
    label: 'SportiqeBoon',
    type: 'pants',
    sizes: [
      { label: 'S', width: '29.5', length: '30.25' },
      { label: 'M', width: '31', length: '30.75' },
      { label: 'L', width: '32.5', length: '31.25' },
      { label: 'XL', width: '34', length: '31.75' },
      { label: '2XL', width: '35.5', length: '32.25' },
    ],
  },
  26452: {
    label: 'SportiqeAva',
    type: 'shirt',
    sizes: [
      { label: 'S', width: '38', length: '22' },
      { label: 'M', width: '40', length: '23' },
      { label: 'L', width: '42', length: '24' },
      { label: 'XL', width: '44', length: '25' },
      { label: '2XL', width: '46', length: '26' },
    ],
  },
  26511: {
    label: 'BioworldYouth',
    type: 'shirt',
    sizes: [
      { label: 'XS', width: '16', length: '21' },
      { label: 'S', width: '17', length: '22.5' },
      { label: 'M', width: '18', length: '24' },
      { label: 'L', width: '19', length: '25' },
      { label: 'XL', width: '20', length: '26.5' },
    ],
  },
  23305: {
    label: 'SportiqeDalyWomen',
    type: 'pants',
    sizes: [
      { label: 'XS', width: '28', length: '27 2/5' },
      { label: 'S', width: '30', length: '28' },
      { label: 'M', width: '32', length: '28 3/5' },
      { label: 'L', width: '34', length: '29 1/4' },
      { label: 'XL', width: '36', length: '29 4/5' },
    ],
  },
  26479: {
    label: 'MJCOpenLegPantMen',
    type: 'pants',
    sizes: [
      { label: 'S', width: '26-28', length: '30' },
      { label: 'M', width: '28-30', length: '31' },
      { label: 'L', width: '30-32', length: '31.5' },
      { label: 'XL', width: '32-34', length: '32' },
      { label: '2XL', width: '34-36', length: '32.5' },
    ],
  },
  26482: {
    label: 'MJCJoggersMen',
    type: 'pants',
    sizes: [
      { label: 'S', width: '30-31', length: '31.25' },
      { label: 'M', width: '32-33', length: '31.25' },
      { label: 'L', width: '34-35', length: '31.25' },
      { label: 'XL', width: '36-37', length: '31.25' },
      { label: '2XL', width: '38-39', length: '31.25' },
    ],
  },
  26489: {
    label: 'SportiqeMenComfy',
    type: 'shirt',
    sizes: [
      { label: 'S', width: '21', length: '27' },
      { label: 'M', width: '22', length: '28' },
      { label: 'L', width: '23', length: '29' },
      { label: 'XL', width: '24', length: '30' },
      { label: '2XL', width: '25', length: '31' },
      { label: '3XL', width: '26', length: '32' },
    ],
  },
  26492: {
    label: 'SportiqeWomenComfy',
    type: 'shirt',
    sizes: [
      { label: 'S', width: '32', length: '25.5' },
      { label: 'M', width: '34', length: '26' },
      { label: 'L', width: '36', length: '26.5' },
      { label: 'XL', width: '39', length: '27' },
      { label: '2XL', width: '42', length: '27.5' },
    ],
  },
};
export default SizeChart;
