const ERROR_MESSAGES = {
  DEFAULT: 'Something went wrong. Please try again.',
  QTY_NOT_AVAILABLE: 'The requested quantity is not available',
};

export const ERROR_CODES = {
  DEFAULT: ERROR_MESSAGES.DEFAULT,
  QTY_NOT_AVAILABLE: ERROR_MESSAGES.QTY_NOT_AVAILABLE,
  QTY_NOT_AVAILABLE_FR: ERROR_MESSAGES.QTY_NOT_AVAILABLE,
  INSUFFICIENT_STOCK: ERROR_MESSAGES.QTY_NOT_AVAILABLE,
  QTY_EXCEEDS_MAXIMUM: ERROR_MESSAGES.QTY_NOT_AVAILABLE,
  PURCHASE_LIMIT_REACHED: ERROR_MESSAGES.QTY_NOT_AVAILABLE,
  PRODUCT_UNAVAILABLE: ERROR_MESSAGES.QTY_NOT_AVAILABLE,
};
