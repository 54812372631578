import React from 'react';
import { string, number, bool, oneOf } from 'prop-types';
import {
  HandheldModeIcon,
  HandheldModeNotAvailableIcon,
  TabletopModeIcon,
  TabletopModeNotAvailableIcon,
  TvModeIcon,
  TvModeNotAvailableIcon,
} from '@nintendo-of-america/component-library';
import * as S from './PlayMode.styles';

export const MODES = {
  TABLETOP: 'TABLETOP',
  TV: 'TV',
  HANDHELD: 'HANDHELD',
};
const SUPPORTED_ICONS = {
  [MODES.TABLETOP]: TabletopModeIcon,
  [MODES.TV]: TvModeIcon,
  [MODES.HANDHELD]: HandheldModeIcon,
};

const NOT_SUPPORTED_ICONS = {
  [MODES.TABLETOP]: TabletopModeNotAvailableIcon,
  [MODES.TV]: TvModeNotAvailableIcon,
  [MODES.HANDHELD]: HandheldModeNotAvailableIcon,
};

const PlayMode = (props) => {
  const { mode, iconSize, supported, title } = props;
  const Icon = supported ? SUPPORTED_ICONS[mode] : NOT_SUPPORTED_ICONS[mode];

  return (
    <S.PlayMode>
      <S.PlayModeIcon $supported={supported}>
        <Icon size={iconSize} />
      </S.PlayModeIcon>
      <p>{title}</p>
    </S.PlayMode>
  );
};

PlayMode.defaultProps = {
  supported: false,
  iconName: 'tv-mode',
  iconSize: 48,
  title: 'tv-mode',
};

PlayMode.propTypes = {
  /** Highlights the playmode if it is supported */
  supported: bool,
  /** Play mode */
  mode: oneOf(Object.keys(MODES)),
  /** Size of the icon in px */
  iconSize: number,
  /** Title of the Play Mode */
  title: string,
};

export default PlayMode;
