import styled, { css } from 'styled-components';

export const Story = styled.div`
  ${({ theme }) => css`
    background: ${theme.color.lightGray4};
    border-radius: ${({ theme }) => theme.borderRadius};
    display: grid;
    grid-template-columns: 1fr;
    padding: ${({ theme }) => theme.spacing[32]};
    align-items: center;

    .storyAsset img {
      display: none;
    }
    ${theme.mediaQuery.tablet} {
      grid-template-columns: 1fr 1fr;
      padding: 0 ${({ theme }) => theme.spacing[32]};

      .storyAsset img {
        display: block;
      }
    }
  `}
`;

export const StoryContent = styled.div`
  p {
    font-weight: 700;

    a {
      margin: 0 ${({ theme }) => theme.spacing[8]};
    }
  }
`;

export const EmphasizedContent = styled.p`
  font-size: ${({ theme }) => theme.font.size.h2Mobile};
  ${({ theme }) => theme.mediaQuery.tabletSmall} {
    font-size: ${({ theme }) => theme.font.size.h2Desktop};
  }
`;
