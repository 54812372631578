import React, { useMemo } from 'react';
import { useRouter } from '@nintendo-of-america/next';
import {
  Breadcrumbs,
  Heading,
  Text,
  MediaGallery,
  PlatformLabel,
  VideoPlayer,
  Label,
  KeyArt,
  defaultTheme as theme,
  DlcIcon,
  Spacer,
} from '@nintendo-of-america/component-library';
import { Grid, GridItem } from '@shared/ui';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import { Link } from '@nintendo-of-america/next';
import { PLATFORM_COLOR } from '@local/lib/constants';
import { ContentRating, DownloadDemo, RichText } from '@local/components';
import usePurchaseOptions from '@local/lib/hooks/usePurchaseOptions';
import Head from 'next/head';
import * as S from './Hero.styles';
import * as C from '../';

function Hero({ product }) {
  const { date, text } = useLocalizer();
  const { locale } = useRouter();

  const purchaseOptions = usePurchaseOptions(product);

  const platformLabel =
    product?.platform?.label || product?.facets?.corePlatforms?.[0];

  const isPreorderable =
    product.prePurchase || product.eshopDetails?.isPreorderable;

  const platformCode =
    product.topLevelCategory?.code === 'GAMES'
      ? platformLabel?.toUpperCase().replace(/ /g, '_')
      : product.topLevelCategory?.code.replace(/ /g, '_');

  const breadCrumbLinks = useMemo(() => {
    const links = [{ href: '/store/', label: text('Store') }];
    if (product.topLevelCategory) {
      const categorySlug = product.topLevelCategory.code
        .toLowerCase()
        .replace(/ /g, '-');

      links.push({
        href: `/store/${categorySlug}/`,
        label: product.topLevelCategory.label,
      });
    }
    if (product.name) {
      links.push({
        href: `/store/products/${product.urlKey}/`,
        label: text(product.name),
      });
    }
    return links.map(({ href, label }) => (
      <Link href={href} key={href}>
        <a>{label}</a>
      </Link>
    ));
  }, [product, text]);

  const galleryItems = useMemo(() => {
    const items = [
      product.productImage && {
        ...product.productImage,
      },
      ...(product.productGallery ?? []),
    ].filter((item) => ['image', 'video'].includes(item?.resourceType));

    return items.map(({ publicId, resourceType }, index) => {
      if (resourceType === 'image') {
        return (
          <KeyArt
            key={publicId}
            assetPath={publicId}
            /** TODO: Source real alt text from contenful. */
            alt={`${product.name} ${index + 1}`}
          />
        );
      }
      if (resourceType === 'video') {
        return <VideoPlayer key={publicId} assetPath={publicId} />;
      }
    });
  }, [product]);

  return (
    <S.HeroSection
      $bgColor={
        product.backgroundColor
          ? `#${product.backgroundColor}`
          : PLATFORM_COLOR[platformCode]
      }
    >
      <S.Hero>
        <S.BreadCrumbContainer>
          <Breadcrumbs currentPage={`/store/products/${product.urlKey}/`}>
            {breadCrumbLinks}
          </Breadcrumbs>
        </S.BreadCrumbContainer>
        <S.GallerySide>
          <div>
            <S.MediaGalleryWrapper>
              <MediaGallery
                ariaLabel={product.name + ' gallery'}
                showLoader={false}
                inInitialViewport={true}
                key={product.sku}
                Head={Head}
              >
                {galleryItems}
              </MediaGallery>
            </S.MediaGalleryWrapper>
            <Spacer size={8} />
            <S.ShowDesktop>
              <Spacer size={8} />
            </S.ShowDesktop>
          </div>
          {(product.platinumPoints ||
            product.exclusive ||
            product.requiresSubscription ||
            product.voucherNsuid) && (
            <>
              <S.Warnings>
                {product.platinumPoints && (
                  <Label backgroundColor="#fde6e7" foregroundColor="#ac000d">
                    {text('My Nintendo Reward')}
                  </Label>
                )}
                {product.exclusive && (
                  <Label backgroundColor="#fde6e7" foregroundColor="#ac000d">
                    {text('Exclusive')}
                  </Label>
                )}
                {product.requiresSubscription && (
                  <Label backgroundColor="#fde6e7" foregroundColor="#ac000d">
                    {text('Nintendo Switch Online required')}
                  </Label>
                )}
                {product.voucherNsuid && (
                  <Label backgroundColor="#fde6e7" foregroundColor="#ac000d">
                    {text('Game Voucher eligible')}
                  </Label>
                )}
              </S.Warnings>
            </>
          )}
          {product.contentRating != null && (
            <>
              <S.ShowDesktop>
                <ContentRating
                  rating={product.contentRating}
                  descriptors={product.contentDescriptors}
                  gamesShown={product.gamesShown}
                />
              </S.ShowDesktop>
            </>
          )}
          <S.ShowDesktop>
            <Spacer size={24} />
            {product.displayChokingHazard ? (
              <S.ChokingHazard>
                <Heading variant="h3" as="strong">
                  {text('Warning:')} <br />
                </Heading>
                <Text variant="legal">
                  {text('CHOKING HAZARD - Small parts.')} <br />
                  {text('Not for children under 3 years.')}
                </Text>
              </S.ChokingHazard>
            ) : null}
            {product.topLegalDisclaimer ? (
              <RichText textVariant="legal" data={product.topLegalDisclaimer} />
            ) : null}
          </S.ShowDesktop>
        </S.GallerySide>
        <S.HeroInfo>
          <S.PlatformContainer>
            {product.dlcType && product.dlcType.code != 'ROM_BUNDLE' && (
              <Label backgroundColor="#3946a0" foregroundColor="#fff">
                DLC
              </Label>
            )}
            <PlatformLabel accentColor={PLATFORM_COLOR[platformCode]}>
              {product.topLevelCategory?.code === 'GAMES'
                ? platformLabel
                : product.topLevelCategory?.label}
            </PlatformLabel>
          </S.PlatformContainer>
          <Spacer size={24} />
          <Heading variant="h1">{product.name}</Heading>
          {isPreorderable && (
            <>
              <Spacer size={16} />
              <Text variant="caption">
                {text('Releases {0}', {
                  args: [
                    product.releaseDateDisplay
                      ? product.releaseDateDisplay
                      : date(product.releaseDate, {
                          format: {
                            day: 'numeric',
                            month: 'numeric',
                            year: '2-digit',
                          },
                        }),
                  ],
                })}
              </Text>
              <Spacer size={8} />
            </>
          )}
          {(product.relatedProducts?.length > 0 || product.demoNsuid) && (
            <>
              <Spacer size={24} />
              <S.ExtraLinks>
                {product.relatedProducts.length > 0 && (
                  <div>
                    <S.StyledIconLink
                      href="#dlc"
                      icon={DlcIcon}
                      locale={locale}
                    >
                      {text('View DLC')}
                    </S.StyledIconLink>
                  </div>
                )}
                {product.demoNsuid && <DownloadDemo product={product} />}
              </S.ExtraLinks>
              <Spacer size={24} />
            </>
          )}
          <C.PurchaseOptions
            product={product}
            purchaseOptions={purchaseOptions}
          />
          {product.baseSoftware?.length
            ? product.dlcType &&
              product.dlcType.code != 'ROM_BUNDLE' && (
                <>
                  <Spacer size={20} />
                  <Link href={product.baseSoftware[0].urlKey}>
                    <a>
                      <Grid
                        columns={'1fr 4fr'}
                        gap={theme.spacing[8]}
                        alignItems="center"
                      >
                        <GridItem column="span 2">
                          <Text variant="legal">
                            {text('Required to play:')}
                          </Text>
                        </GridItem>
                        <S.RequiredImage
                          assetPath={
                            product.baseSoftware[0].productImage.publicId
                          }
                          alt={product.baseSoftware[0].name}
                        />
                        <Text variant="caption">
                          {product.baseSoftware[0].name}
                        </Text>
                      </Grid>
                    </a>
                  </Link>
                </>
              )
            : null}
          <S.ShowMobile>
            <Spacer size={24} />
            {product.displayChokingHazard ? (
              <S.ChokingHazard>
                <Heading variant="h3" as="strong">
                  {text('Warning:')} <br />
                </Heading>
                <Text variant="legal">
                  {text('CHOKING HAZARD - Small parts.')} <br />
                  {text('Not for children under 3 years.')}
                </Text>
              </S.ChokingHazard>
            ) : null}
            {product.topLegalDisclaimer ? (
              <RichText data={product.topLegalDisclaimer}></RichText>
            ) : null}
          </S.ShowMobile>
        </S.HeroInfo>
      </S.Hero>
    </S.HeroSection>
  );
}

export default Hero;
