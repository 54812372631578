import PropTypes from 'prop-types';
import { Spacer, Link } from '@nintendo-of-america/component-library';
import { Grid, GridItem } from '@shared/ui';
import { StyledText } from './OptionDisplays.styles';
import { SelectAttributes } from '@local/components/pages/Products';
import { useLocalizer } from '@nintendo-of-america/react-hooks';

function SwatchDisplay({ onChange, isBundle, product, title, value }) {
  const { text } = useLocalizer();

  return (
    <>
      <Spacer size={24} />
      <Grid columns={2} alignItems="end">
        <StyledText variant="legal">
          {isBundle ? title : text('Select a product')}
        </StyledText>
        {product?.sizeChart && (
          <GridItem justifySelf="end">
            <Link href={'#sizing'}>{text('Size chart')}</Link>
            <Spacer size={8} />
          </GridItem>
        )}
      </Grid>
      <Spacer size={12} />
      <SelectAttributes onChange={onChange} product={product} value={value} />
    </>
  );
}

SwatchDisplay.propTypes = {
  isBundle: PropTypes.bool,
  onChange: PropTypes.func,
  product: PropTypes.shape({
    configurableOptions: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.number,
        swatch: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    sizeChart: PropTypes.shape({}),
    variations: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  title: PropTypes.string,
  value: PropTypes.string,
};
export default SwatchDisplay;
