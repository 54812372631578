import {
  Button,
  Link,
  Image,
  NsoHorizontalLogoIcon,
} from '@nintendo-of-america/component-library';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import { useRouter } from '@nintendo-of-america/next';
import { Section } from '@local/components';

import * as S from './NSOStory.styles';

const NSOStory = ({ product }) => {
  const { text } = useLocalizer();
  const { locale } = useRouter();
  const nsoUrl = '/switch/online/';

  if (!product.nsoFeatures) return <></>;
  return (
    <Section constrained={true} small>
      <S.Story>
        <S.StoryContent>
          <NsoHorizontalLogoIcon size={200} />
          <S.EmphasizedContent>
            {text(
              'Play online, access classic NES™ and Super NES™ games, and more with a Nintendo Switch Online membership.'
            )}
          </S.EmphasizedContent>
          <p>
            {text('This game supports:')}
            {product.nsoFeatures?.map((feature) => (
              <span key={feature.code}>
                {feature.code == 'ONLINE_PLAY' && (
                  <Link key="online-play" href={nsoUrl}>
                    {text('Online Play')}
                  </Link>
                )}
                {feature.code == 'SAVE_DATA_CLOUD' && (
                  <Link key="save-data" href={nsoUrl}>
                    {text('Save Data Cloud')}
                  </Link>
                )}
              </span>
            ))}
          </p>
          <Button href={nsoUrl} buttonWidth="auto" locale={locale}>
            {text('Learn more')}
          </Button>
        </S.StoryContent>
        <div className="storyAsset">
          <Image
            assetPath="https://assets.nintendo.com/image/upload/f_auto,q_auto/v1605147657/ncom/en_US/merchandising/curated%20list/Games%20for%20core%20gamers/Pro-Controller-Hands.png"
            alt={text('Nintendo Switch Pro Controller')}
          />
        </div>
      </S.Story>
    </Section>
  );
};

export default NSOStory;
