import styled from 'styled-components';
import { IconLink } from '@nintendo-of-america/component-library/';

export const ReadMore = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing[16]};

  img {
    max-width: 100%;
  }
`;

export const StyledIconLink = styled(IconLink)`
  padding-left: 0;
`;

export const ChildContent = styled.div`
  > div:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-display: -webkit-box;

    &.clamp {
      -webkit-line-clamp: ${({ $isExpanded, $maxLines }) =>
        $isExpanded ? 9999 : $maxLines};
      -webkit-box-orient: vertical;
    }

    > p {
      margin-bottom: 0;
    }

    margin-bottom: ${({ theme }) => theme.spacing[8]};
  }
`;
