import { useMemo } from 'react';
import { Heading, HeadingCta } from '@nintendo-of-america/component-library';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import { Constrained, ProductCollection } from '@local/components';

import * as S from './DlcRail.styles';
import { storeProductParser } from '@shared/util';

const DlcRail = ({ product, locale }) => {
  const { text } = useLocalizer();

  const dlcProducts = useMemo(() => {
    return product.relatedProducts.slice(0, 4).map(storeProductParser);
  }, [product.relatedProducts]);

  return (
    <>
      <Constrained small>
        {product.relatedProducts.length > 4 ? (
          <HeadingCta
            ctaType="iconlink"
            headingProps={{ variant: 'h1' }}
            ctaText={text('See all')}
            ctaUrl={product.urlKey + '/dlc/'}
            locale={locale}
          >
            {text('Downloadable content (DLC)')}
          </HeadingCta>
        ) : (
          <Heading variant="h1">{text('Downloadable content (DLC)')}</Heading>
        )}
      </Constrained>
      <S.RailBG $bgColor={'#' + product.backgroundColor}>
        <Constrained small>
          <Heading.NewLevel>
            <ProductCollection
              analytics={{ title: 'Downloadable content (DLC)' }}
              products={dlcProducts}
              cta={{
                fields: {
                  title: text('See all'),
                  url:
                    product.relatedProducts.length > 4
                      ? product.urlKey + '/dlc/'
                      : '',
                },
              }}
            />
          </Heading.NewLevel>
        </Constrained>
      </S.RailBG>
    </>
  );
};

export default DlcRail;
