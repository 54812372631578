import styled, { css } from 'styled-components';
import BaseTileLink from '@local/components/TileLink';
import { TileLinkCss } from '@local/components/TileLink/TileLink.styles';

export const StyledAttributes = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 0.5em;
`;

const tileStyles = ({ theme, isActive, swatch, disabled }) => css`
  flex-basis: 50%;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
  background: ${disabled && !swatch
    ? theme.color.lightGray2
    : swatch
      ? 'url(' + swatch + ')'
      : isActive
        ? theme.color.primary
        : theme.color.white};
  background-size: cover;
  background-repeat: no-repeat;
  border: ${theme.spacing[4]} solid
    ${isActive
      ? theme.color.primary
      : disabled
        ? theme.color.lightGray2
        : theme.color.white};
  color: ${disabled
    ? theme.color.darkGray2
    : isActive
      ? theme.color.white
      : theme.color.darkGray2};

  ${theme.mediaQuery.tablet} {
    color: ${disabled
      ? theme.color.darkGray3
      : isActive
        ? theme.color.white
        : theme.color.darkGray2};
  }

  transition: ${({ theme }) =>
    theme.movement.duration + ' ' + theme.movement.easing};
  opacity: ${disabled && swatch ? '60%' : 'initial'};
  filter: ${disabled ? 'grayscale(60%)' : ''};

  &:hover {
    border: ${theme.spacing[4]} solid ${theme.color.primary};

    > * {
      color: ${isActive && !disabled
        ? theme.color.white
        : theme.color.darkGray2};
    }
  }
`;

export const Tile = styled.div`
  ${TileLinkCss}
  ${tileStyles}

  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
`;

export const TileLink = styled(BaseTileLink)`
  ${tileStyles}
`;
