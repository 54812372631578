import React, { useState, useRef, useEffect } from 'react';
import { number, string } from 'prop-types';
import {
  MinusIcon,
  PlusIcon,
  Text,
} from '@nintendo-of-america/component-library';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import { RichText } from '@local/components';
import * as S from './ReadMore.styles';

function ReadMore({ maxLines, description, category }) {
  const { text } = useLocalizer();
  const [collapsedScrollY, setCollapsedScrollY] = useState(0);
  const [showReadMore, setShowReadMore] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const isGame = category?.code === 'GAMES';
  const ref = useRef(null);
  const childRef = useRef(null);

  const timeoutRef = useRef(null);

  useEffect(() => {
    checkForClamp();
    window.addEventListener('resize', checkForClamp);
    return () => {
      window.removeEventListener('resize', checkForClamp);
    };
  }, []);

  useEffect(() => {
    setIsExpanded(false);
  }, [description]);

  const checkForClamp = () => {
    clearTimeout(timeoutRef.current);
    // timeout necessary because function does not read element-dimensions appropriately on pageload
    timeoutRef.current = setTimeout(() => {
      // if scrollHeight (height of container, even if part is overflow: hidden) is greater than clientHeight (the visible height of the container), then clamping is being used
      setShowReadMore(
        childRef?.current?.scrollHeight > childRef?.current?.clientHeight
      );
    }, 0);
  };

  useEffect(() => {
    childRef.current?.classList.add('clamp');
  }, [maxLines]);

  const handleExpandClick = () => {
    setIsExpanded((isExpanded) => {
      if (isExpanded) {
        window.scrollTo(0, collapsedScrollY);
      } else {
        setCollapsedScrollY(window.scrollY);
      }
      return !isExpanded;
    });
  };

  return (
    <S.ReadMore ref={ref}>
      <S.ChildContent $isExpanded={isExpanded} $maxLines={maxLines}>
        <RichText ref={childRef} data={description} />
      </S.ChildContent>
      {showReadMore && (
        <S.StyledIconLink
          icon={isExpanded ? MinusIcon : PlusIcon}
          onClick={handleExpandClick}
        >
          {isExpanded ? text('Read less') : text('Read more')}
        </S.StyledIconLink>
      )}
      {isGame && (
        <Text variant="legal">
          {text('Software description provided by the publisher.')}
        </Text>
      )}
    </S.ReadMore>
  );
}

ReadMore.propTypes = {
  description: string,
  maxLines: number,
};

export default ReadMore;
