import { useMemo } from 'react';
import { object, string } from 'prop-types';
import { useRouter } from '@nintendo-of-america/next';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import {
  ShoppingCartIcon,
  DownloadIcon,
  Price,
} from '@nintendo-of-america/component-library';
import { isLatam } from '@shared/util';
import { PriceSpider } from '@local/components';
import * as S from './StickyCta.styles';

const StickyCta = (props) => {
  const { product, purchaseOptions, errorMessage } = props;
  const { text } = useLocalizer();
  const { query, locale } = useRouter();
  const lang = locale?.split('-')[0] ?? 'en';

  const { name, eshopDetails, topLevelCategory, platinumPoints } = product;

  const {
    state: {
      isLoading,
      isDigitalProduct,
      isPurchasable,
      isMobileGame,
      buttonDisabled,
      promptLogin,
      buttonCopy,
      displayPrice,
      retailerSku,
    },
    actions: { handleDirectDownloadClick, handleAddToCart },
  } = purchaseOptions;

  const hasVariants =
    product.variations?.length && !product.configurableProduct;
  const selectedVariant = useMemo(() => {
    return product.variations?.find(
      (variation) => variation?.product?.urlKey === query.slug
    );
  }, [product, query]);

  const configurableType = selectedVariant?.product.size ? text('Size') : null;
  const configurableValue = selectedVariant?.label;
  const configurableLabel = configurableType
    ? `${configurableType}: ${configurableValue}`
    : configurableValue;

  let variantLabel = null;
  if (topLevelCategory?.code === 'GAMES') {
    variantLabel = isDigitalProduct ? text('Digital') : text('Physical');
  } else if (hasVariants && !selectedVariant) {
    variantLabel = text('Starting at');
  } else if (configurableLabel) {
    variantLabel = configurableLabel;
  }

  const handleAddProductToCart = useMemo(() => {
    if (hasVariants) {
      return () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
    }

    return handleAddToCart;
  }, [hasVariants, handleAddToCart]);

  return (
    <S.Container>
      <S.Wrapper $hasError={!!errorMessage}>
        <S.ProductName forwardedAs="p" variant="h3">
          {name}
        </S.ProductName>
        <S.CtaContainer>
          <S.ProductDetails>
            {variantLabel && (
              <S.VariantLabel $smallMargin={!selectedVariant}>
                {variantLabel}
              </S.VariantLabel>
            )}
            {!isMobileGame &&
              (Boolean(displayPrice.regPrice) || Boolean(platinumPoints)) && (
                <S.PricingContainer>
                  {isLoading && <S.PricingSkeleton width={'100%'} />}
                  <S.Pricing $visible={!isLoading}>
                    {platinumPoints ? (
                      <S.Grid
                        gap={'0.5rem'}
                        justifyItems="start"
                        alignItems="center"
                      >
                        <S.MyNintendoPlatinumCoinIcon />
                        <S.PlatinumPointsLabel variant="h2">
                          {platinumPoints} {text('Platinum Points')}
                        </S.PlatinumPointsLabel>
                      </S.Grid>
                    ) : !isLatam(locale) || isDigitalProduct ? (
                      <Price
                        regPrice={displayPrice.regPrice}
                        horizontal
                        size="medium"
                        salePrice={displayPrice.salePrice}
                        lang={lang}
                      />
                    ) : null}
                  </S.Pricing>
                </S.PricingContainer>
              )}
          </S.ProductDetails>

          <div>
            {isDigitalProduct ? (
              isPurchasable && (
                <S.Button
                  onClick={handleDirectDownloadClick}
                  icon={buttonDisabled || promptLogin ? '' : DownloadIcon}
                  isLoading={isLoading}
                  buttonWidth="full"
                  disabled={buttonDisabled}
                  round={true}
                  size="small"
                  href={buttonDisabled ? null : eshopDetails?.purchaseUrl}
                >
                  {typeof buttonCopy === 'string'
                    ? text(buttonCopy)
                    : buttonCopy}
                </S.Button>
              )
            ) : !isLatam(locale) ? (
              <S.Button
                onClick={handleAddProductToCart}
                isLoading={isLoading}
                disabled={buttonDisabled}
                icon={buttonDisabled || promptLogin ? '' : ShoppingCartIcon}
                buttonWidth="full"
                round={true}
                size="small"
              >
                {typeof buttonCopy === 'string' ? text(buttonCopy) : buttonCopy}
              </S.Button>
            ) : (
              <PriceSpider
                sku={retailerSku}
                name={product.name}
                type="button-round"
              />
            )}
            {errorMessage && (
              <S.ErrorText variant="legal">{errorMessage}</S.ErrorText>
            )}
          </div>
        </S.CtaContainer>
      </S.Wrapper>
    </S.Container>
  );
};

StickyCta.propTypes = {
  product: object,
  configurable: object,
  purchaseOptions: object,
  errorMessage: string,
};

export default StickyCta;
