import React from 'react';
import PropTypes from 'prop-types';
import { Link as TextLink } from '@nintendo-of-america/next';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import { Spacer, Text } from '@nintendo-of-america/component-library';
import * as S from './OptionDisplays.styles';

const RadioDetailed = React.forwardRef(function RadioDetailed(props, ref) {
  const {
    checked,
    onClick,
    href,
    onChange = () => {},
    product,
    variation,
  } = props;
  const { text, price } = useLocalizer();

  return (
    <S.StyledRadioDetailed
      ref={ref}
      caption={variation.product.name}
      checked={checked}
      discounted={variation.product.prices?.minimum?.discounted}
      href={href}
      price={
        product.loadingPricing
          ? null
          : price(variation.product?.prices?.minimum?.finalPrice)
      }
      regularPrice={
        product.loadingPricing
          ? null
          : price(variation.product?.prices?.minimum?.regularPrice)
      }
      onChange={onChange}
      onClick={onClick}
      value={variation.product.urlKey}
      name={variation.product.name}
      label={variation.product.nsuid ? text('Digital') : text('Physical')}
      altContent="✓"
    />
  );
});

function RadioDisplay({ onChange, isBundle, product, title, value }) {
  const { text } = useLocalizer();

  return (
    <>
      <Text variant="legal">{isBundle ? title : text('Select a product')}</Text>
      <Spacer size={12} />
      <div>
        {product.variations.map((variation) =>
          variation.product.url ? (
            <TextLink key={variation.product.sku} href={variation.product.url}>
              <RadioDetailed
                checked={product.url === variation.product.url}
                product={product}
                variation={variation}
              />
            </TextLink>
          ) : (
            <RadioDetailed
              key={variation.id}
              checked={value === variation.id}
              product={product}
              variation={variation}
              onChange={() => onChange(variation.id)}
            />
          )
        )}
      </div>
    </>
  );
}

RadioDisplay.propTypes = {
  onChange: PropTypes.func,
  isBundle: PropTypes.bool,
  product: PropTypes.shape({
    variations: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  selectedId: PropTypes.string,
  title: PropTypes.string,
};

RadioDisplay.defaultProps = {
  onChange: () => {},
};

export default RadioDisplay;
